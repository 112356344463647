import CareerHero from "../sections/careerspage-sections/CareerHero";

const Career = () => {
  return (
    <div className="w-full bg-primary">
      <CareerHero />
    </div>
  );
};
export default Career;
