import React from 'react'
import PricingPlans from '../sections/PricingPlans'
import ContactForm from '../sections/ContactForm'

export default function Pricing() {
    return (
        <>
            <PricingPlans />

            <ContactForm />
        </>








    )
}
